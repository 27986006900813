<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <div class="fluid" id="employeeList">
          <div class="d-flex">
            <div class="employee-list-div">
              <v-card elevation="0" class="px-3 py-2" style="width: 100%">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex"
                    style="
                      position: relative;
                      padding: 0 20px;
                      text-align: center;
                      margin-top: 20px;
                    "
                  >
                    <h6
                      class="indigo--text"
                      style="font-weight: bold; margin: auto"
                    >
                      DATA KARYAWAN
                    </h6>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <v-toolbar class="employee-list-toolbar-1" flat>
                        <div
                          style="width: 100%"
                          class="d-flex justify-space-between"
                        >
                          <div v-if="permission.create_perm == 1">
                            <router-link to="/hr/employee/registration_form">
                              <v-btn
                                outlined
                                elevation="1"
                                color="indigo"
                                class="indigo--text font-weight-bold"
                                style="font-size: 12px; margin-left: 5px"
                              >
                                Formulir Registrasi
                              </v-btn>
                            </router-link>
                          </div>
                          <div class="d-flex">
                            <div
                              v-if="
                                permission.create_perm == 1 &&
                                permission.update_perm == 1 &&
                                permission.delete_perm == 1
                              "
                              style="
                                margin-left: 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <div>
                                <v-btn
                                  rounded
                                  outlined
                                  small
                                  elevation="1"
                                  color="indigo"
                                  class="indigo--text"
                                  style="font-size: 12px"
                                  @click="exportData"
                                >
                                  Export
                                </v-btn>
                              </div>
                            </div>
                            <div
                              v-if="
                                permission.create_perm == 1 &&
                                permission.update_perm == 1
                              "
                              style="
                                margin-left: 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <div>
                                <v-btn
                                  rounded
                                  small
                                  elevation="1"
                                  color="indigo"
                                  class="white--text"
                                  style="font-size: 12px"
                                  @click.stop="importData"
                                >
                                  Import
                                  <v-icon size="15" style="margin-left: 5px">
                                    mdi-file-import-outline
                                  </v-icon>
                                </v-btn>
                              </div>
                            </div>

                            <div
                              class="employee-list-toolbar-div"
                              style="margin-left: 20px; width: 300px"
                            >
                              <v-text-field
                                v-model="paramAPI.keyword"
                                label="Cari disini"
                                type="search"
                                outlined
                                dense
                                append-icon="mdi-magnify"
                                style="position: relative; top: 15px"
                                @keyup.enter="searchEnter"
                                :disabled="loading"
                              ></v-text-field>
                            </div>
                          </div>
                        </div>
                      </v-toolbar>
                      <v-toolbar class="employee-list-toolbar-1" flat>
                        <div
                          v-if="
                            permission.create_perm == 1 &&
                            permission.update_perm == 1 &&
                            permission.delete_perm == 1
                          "
                        >
                          <div style="width: 140px">
                            <v-select
                              :disabled="selectedData.length === 0"
                              label="Action"
                              style="
                                position: relative;
                                top: 15px;
                                margin-left: 5px;
                                font-size: 12px;
                              "
                              v-model="actionValue"
                              :items="[
                                { id: 0, name: '' },
                                { id: 1, name: 'Delete' },
                                { id: 2, name: 'Inactivate' },
                                { id: 3, name: 'Reset Device' },
                                { id: 4, name: 'Reset Password' }
                              ]"
                              item-text="name"
                              item-value="id"
                              outlined
                              return-id
                              dense
                              @change="action"
                            ></v-select>
                          </div>
                        </div>
                        <div style="width: 150px; margin-left: 10px">
                          <v-select
                            @change="companyWatcher"
                            v-model="paramAPI.company_id"
                            :items="dropdown.company"
                            style="
                              position: relative;
                              top: 15px;
                              margin-left: 5px;
                              font-size: 12px;
                            "
                            label="Company"
                            item-text="name"
                            item-value="id"
                            outlined
                            return-id
                            dense
                            :disabled="isDisableCompanyDropdown"
                          ></v-select>
                        </div>
                        <div style="width: 140px; margin-left: 20px">
                          <v-select
                            @change="departmentWatcher"
                            label="Dept/ Section"
                            v-model="paramAPI.department_id"
                            :items="dropdown.department"
                            style="
                              position: relative;
                              top: 15px;
                              font-size: 12px;
                            "
                            item-text="name"
                            item-value="id"
                            return-id
                            dense
                            outlined
                            clearable
                          ></v-select>
                        </div>
                        <div style="width: 140px; margin-left: 20px">
                          <v-select
                            @change="levelWatcher"
                            label="Jabatan"
                            v-model="paramAPI.level_id"
                            :items="dropdown.level"
                            style="
                              position: relative;
                              top: 15px;
                              font-size: 12px;
                            "
                            item-text="name"
                            item-value="id"
                            return-id
                            dense
                            outlined
                            clearable
                          ></v-select>
                        </div>
                        <div style="width: 140px; margin-left: 20px">
                          <v-select
                            @change="statusWatcher"
                            label="Status"
                            v-model="paramAPI.employee_status_id"
                            :items="dropdown.employee_status"
                            style="
                              position: relative;
                              top: 15px;
                              font-size: 12px;
                            "
                            item-text="name"
                            item-value="id"
                            return-id
                            dense
                            outlined
                            clearable
                          ></v-select>
                        </div>
                      </v-toolbar>
                    </div>
                    <div class="employee-list-toolbar-2">
                      <v-expansion-panels flat accordion>
                        <v-expansion-panel v-for="(item, i) in 1" :key="i" flat>
                          <v-expansion-panel-header
                            class="d-flex align-items-start"
                            style="padding: 0 0 0 15px"
                          >
                            <p style="color: rgba(0, 0, 0, 0.4)">
                              Tampilkan Filter & Pencarian
                            </p>
                            <template v-slot:actions>
                              <v-icon color="primary"> $expand </v-icon>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content id="expansionPanelContent">
                            <v-select
                              @change="companyWatcher"
                              v-model="paramAPI.company_id"
                              :items="dropdown.company"
                              style="height: 50px"
                              label="Company"
                              item-text="name"
                              item-value="id"
                              outlined
                              return-id
                              dense
                              :disabled="isDisableCompanyDropdown"
                            ></v-select>
                            <v-select
                              @change="departmentWatcher"
                              label="Dept/ Section"
                              v-model="paramAPI.department_id"
                              :items="dropdown.department"
                              style="height: 50px"
                              item-text="name"
                              item-value="id"
                              outlined
                              return-id
                              dense
                              clearable
                            ></v-select>
                            <v-select
                              @change="levelWatcher"
                              label="Jabatan"
                              v-model="paramAPI.level_id"
                              :items="dropdown.level"
                              style="height: 50px"
                              item-text="name"
                              item-value="id"
                              outlined
                              return-id
                              dense
                              clearable
                            ></v-select>
                            <v-text-field
                              v-model="paramAPI.keyword"
                              label="Cari disini"
                              type="search"
                              outlined
                              dense
                              append-icon="mdi-magnify"
                              @keyup.enter="searchEnter"
                              :disabled="loading"
                            ></v-text-field>

                            <div
                              v-if="userLevel.is_admin == 1"
                              flat
                              style="
                                border-radius: 10px 10px 0 0;
                                border-bottom: 1px solid #e0e0e0;
                                width: 100%;
                                margin-bottom: 10px;
                              "
                            >
                              <v-btn
                                outlined
                                rounded
                                elevation="1"
                                color="indigo"
                                class="white--text"
                                style="
                                  font-size: 12px;
                                  position: relative;
                                  width: 100%;
                                "
                                @click="exportData"
                                >Export</v-btn
                              >
                            </div>
                            <div
                              v-if="
                                permission.create_perm == 1 &&
                                permission.update_perm == 1
                              "
                              flat
                              style="
                                border-radius: 10px 10px 0 0;
                                border-bottom: 1px solid #e0e0e0;
                                width: 100%;
                                margin-bottom: 10px;
                              "
                            >
                              <v-btn
                                rounded
                                elevation="1"
                                color="indigo"
                                class="white--text"
                                style="
                                  font-size: 12px;
                                  position: relative;
                                  width: 100%;
                                "
                                @click.stop="importData"
                              >
                                Import
                                <v-icon size="15" style="margin-left: 5px">
                                  mdi-file-import-outline
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <div
                        v-if="
                          userLevel.is_admin == 1 &&
                          permission.create_perm == 1 &&
                          permission.update_perm == 1 &&
                          permission.delete_perm == 1
                        "
                        style="padding: 0 10px"
                      >
                        <v-select
                          :disabled="selectedData.length === 0"
                          label="Action"
                          style="
                            position: relative;
                            top: 15px;
                            margin-left: 5px;
                            font-size: 12px;
                          "
                          v-model="actionValue"
                          :items="[
                            { id: 0, name: '' },
                            { id: 1, name: 'Delete' },
                            { id: 2, name: 'Unactivated' },
                            { id: 3, name: 'Reset Device' },
                            { id: 4, name: 'Reset Password' }
                          ]"
                          item-text="name"
                          item-value="id"
                          outlined
                          return-id
                          dense
                          @change="action"
                        ></v-select>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" style="width: 100%">
                    <v-data-table
                      v-if="result.length > 0 || !skeleton_loading"
                      mobile-breakpoint="0"
                      fixed-header
                      height="60vh"
                      v-model="selectedData"
                      :headers="headers"
                      style="cursor: pointer"
                      :items="result"
                      :loading="loading"
                      @click:row="rowClick"
                      item-key="id"
                      :show-select="
                        userLevel.is_admin == 1 &&
                        permission.create_perm == 1 &&
                        permission.update_perm == 1 &&
                        permission.delete_perm == 1
                      "
                      :footer-props="{
                        showFirstLastPage: true,
                        showCurrentPage: true,
                        itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                      }"
                      :page="paramAPI.page"
                      :server-items-length="totalData"
                      :items-per-page="paramAPI.itemsPerPage"
                      @update:page="updatePage"
                      @update:items-per-page="updateItemPerPage"
                    >
                      <template v-slot:[`item.level`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.level !== null ? item.level.name : '-' }}
                        </div>
                      </template>
                      <!--  <template v-slot:[`item.nik`]="{ item }">
                  <div
                    style="width:110px; font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.nik }}
                  </div>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <div
                    style="width:210px; font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.email }}
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="width:160px; font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.name }}
                  </div>
                </template> -->
                      <template v-slot:[`item.department`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{
                            item.department !== null
                              ? item.department.name
                              : '-'
                          }}
                          -
                          {{
                            item.department !== null
                              ? item.department.section !== null
                                ? item.department.section.name
                                : '-'
                              : '-'
                          }}
                        </div>
                      </template>
                      <!-- <template v-slot:[`item.company_name`]="{ item }">
                  <div
                    style="width:160px; font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.company_name }}
                  </div>
                </template> -->
                      <template v-slot:[`item.status`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ statusData(item.status) }}
                        </div>
                      </template>
                      <template v-slot:[`item.employee_status_id`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ statusEmployee(item.employee_status_id) }}
                        </div>
                      </template>
                      <template v-slot:[`item.created_at`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.created_at) }}
                          {{
                            item.created_by_name !== undefined
                              ? item.created_by_name !== null
                                ? `(${item.created_by_name})`
                                : ''
                              : ''
                          }}
                        </div>
                      </template>
                      <template v-slot:[`item.updated_at`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.updated_at) }}
                          {{
                            item.updated_by_name !== undefined
                              ? item.updated_by_name !== null
                                ? `(${item.updated_by_name})`
                                : ''
                              : ''
                          }}
                        </div>
                      </template>
                    </v-data-table>
                    <v-skeleton-loader
                      v-else
                      style="width: 100%"
                      type="table-heading, table-tbody, table-tfoot"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card>

              <div id="triangle" class="triangle-maximize">
                <div
                  class="triangle-with-shadow d-flex justify-center align-center"
                  @click="showlog"
                >
                  <p :class="`p-log ${!isLog ? 'p-log-1' : 'p-log-2'}`">Log</p>
                </div>
              </div>
            </div>
            <div id="updateHistory" class="log-hide">
              <div class="history-background">
                <v-row no-gutters style="height: 630px">
                  <v-col
                    cols="12"
                    class="d-flex"
                    style="
                      padding: 0 20px;
                      text-align: center;
                      margin-top: 10px;
                    "
                  >
                    <h6
                      class="indigo--text"
                      style="
                        font-weight: bold;
                        margin: auto;
                        font-size: 14px;
                        width: 220px;
                        overflow: hidden;
                      "
                    >
                      UPDATE HISTORY
                    </h6>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex"
                    style="
                      height: 100%;
                      padding: 5px 5px 35px 5px;
                      text-align: center;
                    "
                  >
                    <div style="height: 100%; width: 100%; padding-top: 5px">
                      <!-- <div style="width: 120px; margin: auto;">
                  <p
                    style="margin: 0; font-size: 12px; font-weight: bolder; color: #494949;"
                  >
                    Desember 29, 2023
                  </p>
                </div> -->
                      <div
                        style="
                          margin-top: 5px;
                          border-top: 1px dashed #a1a1a1;
                          height: 100%;
                          overflow: auto;
                          padding: 5px;
                        "
                      >
                        <v-row
                          v-for="(item, index) in log"
                          :key="index"
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            style="
                              padding: 5px;
                              background-color: #e0e0e0;
                              border-radius: 5px;
                              margin-top: 10px;
                            "
                          >
                            <div class="d-flex align-center">
                              <p
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bolder;
                                "
                              >
                                {{ item.employee_name }}
                              </p>
                              <p style="margin: 0 0 0 3px; font-size: 10px">
                                - {{ item.created_at }}
                              </p>
                            </div>
                            <div class="d-flex">
                              <p style="margin: 0; font-size: 10px">
                                {{ item.company_name }}
                              </p>
                            </div>
                            <div
                              class="d-flex justify-between align-center"
                              style="width: 100%"
                            >
                              <div
                                v-if="item.body.before !== undefined"
                                style="
                                  border: 1px solid #a1a1a1;
                                  width: 130px;
                                  padding: 2px;
                                "
                              >
                                <div v-if="item.body.before !== null">
                                  <p
                                    style="
                                      margin: 0;
                                      font-size: 10px;
                                      text-align: start;
                                    "
                                  >
                                    Sebelum:
                                  </p>
                                  <div class="box-before-after">
                                    <p
                                      style="
                                        margin: 0;
                                        font-size: 10px;
                                        text-align: start;
                                      "
                                    >
                                      {{ JSON.stringify(item.body.before) }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <v-icon x-small>mdi-arrow-right</v-icon>

                              <div
                                v-if="item.body.after !== undefined"
                                style="
                                  border: 1px solid #a1a1a1;
                                  width: 130px;
                                  padding: 2px;
                                "
                              >
                                <div v-if="item.body.after !== null">
                                  <p
                                    style="
                                      margin: 0;
                                      font-size: 10px;
                                      text-align: start;
                                    "
                                  >
                                    Sesudah:
                                  </p>
                                  <div class="box-before-after">
                                    <p
                                      style="
                                        margin: 0;
                                        font-size: 10px;
                                        text-align: start;
                                      "
                                    >
                                      {{ JSON.stringify(item.body.after) }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <div
            v-if="loader"
            class="d-flex justify-center align-center"
            style="
              position: absolute;
              top: 0;
              z-index: 3;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.5);
            "
          >
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <EmployeeImport @loadPage="getDataFromApi" />
        </div>
      </div>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      DATA NOT FOUND
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import * as XLSX from 'xlsx/xlsx.mjs'
// config
const env = process.env.VUE_APP_BUILD_TYPE
// const config = require('../../../services/config.json')[env]
// console.log('config & env', env, config)

export default {
  name: 'employee',
  components: {
    EmployeeImport: () => import('./components/Import.vue')
  },
  data: () => ({
    config: require('../../../services/config.json')[env],
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    logApi: buildType.apiURL('log'),
    eSanqua: buildType.apiURL('esanqua'),
    oAuthApi: buildType.apiURL('oauth'),
    permission: null,
    appLevel: null,
    userLevel: null,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      employee_status_id: null,
      sortBy: 'name',
      sortType: 'asc',
      company_id: null,
      department_id: null,
      level_id: null,
      section_id: null,
      itemsPerPage: 10
    },
    totalData: 0,

    options: {},
    actionValue: 0,
    // headers: [],
    result: [],
    dropdown: {
      employee_status: [],
      section: [],
      company: [],
      department: [],
      level: []
    },
    selectedData: [],
    loading: true,
    nameRules: [],
    loader: false,
    isLog: false,
    log: [],
    isLoadingPage: true,
    isDisableCompanyDropdown: false,
    skeleton_loading: true
  }),

  async mounted() {
    this.appLevel = this.config.application.hr.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    this.getDataMenu()

    // this.paramAPI.company_id = Number(
    //   this.getUserProfile.employee.company.plant_id
    // )
    // if (this.getEmployeePageQuery !== null) {
    //   this.paramAPI = this.getEmployeePageQuery
    // }
    // setTimeout(async () => {
    //   await this.getDataFromApi()
    //   this.initDropdown()
    //   this.setAction()
    // }, 200)
  },
  watch: {},
  computed: {
    // 'getDropdownPlant',
    ...mapGetters([
      'getUserProfile',
      'getEmployeePageQuery'
      // 'getDropdownPlant'
    ]),
    headers() {
      const arrHeaders = [
        {
          text: 'NIK',
          value: 'nik',
          align: 'left',
          sortable: true
        },
        {
          text: 'Nama',
          value: 'name',
          align: 'left',
          sortable: true
        },
        {
          text: 'Email',
          value: 'email',
          align: 'left',
          sortable: true
        },
        {
          text: 'Perusahaan',
          value: 'company_name',
          align: 'left',
          sortable: true
        },
        {
          text: 'Department',
          value: 'department',
          align: 'left',
          sortable: true
        },
        {
          text: 'Jabatan',
          value: 'level',
          align: 'left',
          sortable: true
        },
        {
          text: 'Status Karyawan',
          value: 'employee_status_id',
          align: 'left',
          sortable: true
        },
        // {
        //   text: 'Status',
        //   value: 'status',
        //   align: 'left',
        //   sortable: true
        // },
        {
          text: 'Dibuat',
          value: 'created_at',
          align: 'left',
          sortable: true
        },
        {
          text: 'Diupdate',
          value: 'updated_at',
          align: 'left',
          sortable: true
        }
      ]

      if (!this.isLog) {
        return arrHeaders
      } else {
        return arrHeaders.toSpliced(5, 2)
      }
    }
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),
    ...mapMutations(['setEmployeePageQuery']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, input) {
      const settings = {
        position: 'center',
        icon: pModalType,
        html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
        showConfirmButton: true,
        showCancelButton: pBtnCancel,
        cancelButtonText: 'No'
      }

      if (input !== undefined) {
        if (input) {
          Object.assign(settings, {
            inputPlaceholder: 'Tuliskan keterangan',
            input: 'text'
            // inputAttributes: {
            //   autocapitalize: 'false'
            // }
          })
        }
      }

      return new Promise((resolve) => {
        this.$swal.fire(settings).then((r) => {
          resolve(r)
        })
      })
    },
    async getDataMenu() {
      this.isLoadingPage = true
      await this.getMenuId({
        keyword: this.paramAPI.keyword,
        path: this.$route.path
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId ||
                application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              if (
                this.getUserProfile.level.find(
                  ({ id }) => id == this.appLevel.administrator
                ) !== undefined
              ) {
                this.resetPermission({
                  data: {
                    read_perm: 1,
                    create_perm: 1,
                    update_perm: 1,
                    delete_perm: 1
                  }
                })
              } else {
                this.checkMenuAccess([menuId, this.userLevel.id])
              }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    initDataForLayout() {
      if (this.userLevel.user_user_level.allowed_company !== null) {
        if (
          this.userLevel.user_user_level.allowed_company.find(
            ({ id }) => id == this.getUserProfile.employee.company.plant_id
          ) !== undefined
        ) {
          this.paramAPI.company_id = Number(
            this.getUserProfile.employee.company.plant_id
          )
        } else {
          this.paramAPI.company_id = Number(
            this.userLevel.user_user_level.allowed_company[0].id
          )
        }
      } else {
        this.paramAPI.company_id = Number(
          this.getUserProfile.employee.company.plant_id
        )
      }
      console.log('company_id>>>>', this.paramAPI.company_id)
      // if (this.userLevel.is_support) {
      //   this.paramAPI.company_id = 0
      // } else {
      //   this.paramAPI.company_id = Number(
      //     this.getUserProfile.employee.company.plant_id
      //   )
      // }
      if (this.getEmployeePageQuery !== null) {
        this.paramAPI = this.getEmployeePageQuery
      }
      setTimeout(async () => {
        await this.getDataFromApi()
        this.initDropdown()
        this.setAction()
      }, 200)
    },
    initDropdown() {
      this.getDropdownCompany()
      // this.dropdownPlant()
      this.dropdownDepartment(this.paramAPI.company_id)
      this.dropdownLevel()
      this.dropdownEmployeeStatus()
    },
    close() {
      this.form = {
        act: 'add',
        id: '',
        name: ''
      }
      this.dialog = false
    },
    action(event) {
      switch (event) {
        case 1:
          this.delete()
          break
        case 2:
          if (this.selectedData.length < 2) {
            this.nonActiveEmployee()
          } else {
            this.showMsgDialog('warning', 'Pilih salah satu untuk edit', false)
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = 0
            }, 200)
          }
          break
        case 3:
          this.resetDevice()
          break
        case 4:
          if (this.selectedData.length < 2) {
            this.resetPassword()
          } else {
            this.showMsgDialog('warning', 'Pilih salah satu untuk edit', false)
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = 0
            }, 200)
          }
          break
      }
    },
    async searchEnter() {
      this.resetSomeParam()
      await this.getDataFromApi()
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },

    async getDataFromApi() {
      this.loading = true
      this.selectedData = []
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            return (this.result = data.data)
          }
          return (this.result = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.skeleton_loading = false
      this.loading = false
    },
    initDataTable() {
      let url = `${this.hrsApi}employee/list?keyword=${
        this.paramAPI.keyword
      }&company_id=${this.paramAPI.company_id}&order_by=${
        this.paramAPI.sortBy
      }&order_type=${this.paramAPI.sortType}&offset=${
        this.paramAPI.offset * this.paramAPI.limit
      }&limit=${this.paramAPI.limit}`

      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id}`
      }
      if (this.paramAPI.level_id !== null) {
        url = url + `&level_id=${this.paramAPI.level_id}`
      }
      if (this.paramAPI.employee_status_id !== null) {
        url = url + `&employee_status_id=${this.paramAPI.employee_status_id}`
      }
      this.setEmployeePageQuery(this.paramAPI)
      // this.setEmployeePageQuery({
      //   keyword: this.paramAPI.keyword,
      //   company_id: this.paramAPI.company_id,
      //   order_by: this.paramAPI.sortBy,
      //   order_type: this.paramAPI.sortType,
      //   offset: this.paramAPI.offset,
      //   limit: this.paramAPI.limit,
      //   department_id: this.paramAPI.department_id,
      //   level_id: this.paramAPI.level_id,
      //   page: this.paramAPI.page
      // })
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((res) => {
            this.getLog()
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    rowClick(pItem) {
      if (this.permission.read_perm == 1) {
        this.$router.push(`/hr/employee/detail/${pItem.id}`)
      }
    },
    delete() {
      this.showMsgDialog(
        'question',
        `${this.selectedData.length} data akan dihapus secara permanent, Anda yakin ?`,
        true
      ).then((res) => {
        if (res.isConfirmed) {
          this.startDelete()
        } else {
          this.actionValue = 0
        }
      })
    },
    async startDelete() {
      this.loader = true
      const arrSuccess = []
      const arrFail = []
      let i = 0
      while (
        (await new Promise((resolve, reject) => {
          axios
            .delete(`${this.hrsApi}employee/delete/${this.selectedData[i].id}`)
            .then((res) => {
              if (res.data.status_code === '00') {
                arrSuccess.push({
                  index: i,
                  msg: res.data.status_msg
                })
              } else {
                arrFail.push({
                  index: i,
                  msg: res.data.status_msg
                })
              }
              resolve(i++)
            })
            .catch((err) => {
              console.log(err)
              arrFail.push({
                index: i,
                msg: 'error'
              })
              reject(new Error(i++))
            })
        })) <
        this.selectedData.length - 1
      ) {
        console.log('cek ' + i)
      }

      this.loader = false
      this.actionValue = 0
      this.showMsgDialog(
        'success',
        `${arrSuccess.length} data berhasil terhapus <br/> ${arrFail.length} data gagal terhapus pada index [${arrFail}]`,
        false
      )
      return this.getDataFromApi()
    },

    async nonActiveEmployee() {
      this.showMsgDialog(
        'question',
        'Anda yakin ingin meng-nonaktifkan data karyawan ini',
        true,
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .post(`${this.hrsApi}employee/non_active`, {
              id: this.selectedData[0].id,
              note: res.value
            })
            .then(async (res) => {
              if (res.data.status_code === '00') {
                await this.getDataFromApi()
                return this.showMsgDialog(
                  'success',
                  res.data.status_msg,
                  'false'
                )
              }
              return this.showMsgDialog('warning', res.data.status_msg, 'false')
            })
            .catch((err) => {
              this.showMsgDialog('error', `${err}`, 'false')
              return (this.result = [])
            })
          this.loading = false
        }
        //  else {
        this.actionValue = 0
        // }
      })
    },
    resetDevice() {
      this.showMsgDialog(
        'question',
        `${this.selectedData.length} data registrasi perangkat karyawan akan di reset, Anda yakin ?`,
        true
      ).then((res) => {
        if (res.isConfirmed) {
          this.startResetDevice()
        } else {
          this.actionValue = 0
        }
      })
    },
    async startResetDevice() {
      this.loader = true
      const arrSuccess = []
      const arrFail = []
      let i = 0
      while (
        (await new Promise((resolve, reject) => {
          axios
            .post(`${this.hrsApi}employee/reset_device`, {
              employee_id: this.selectedData[0].id
            })
            .then((res) => {
              if (res.data.status_code === '00') {
                arrSuccess.push({
                  index: i,
                  msg: res.data.status_msg
                })
              } else {
                arrFail.push({
                  index: i,
                  msg: res.data.status_msg
                })
              }
              resolve(i++)
            })
            .catch((err) => {
              console.log(err)
              arrFail.push({
                index: i,
                msg: 'error'
              })
              reject(new Error(i++))
            })
        })) <
        this.selectedData.length - 1
      ) {
        console.log('cek ' + i)
      }

      this.loader = false
      this.actionValue = 0
      this.showMsgDialog(
        'success',
        `${arrSuccess.length} data berhasil di reset <br/> ${arrFail.length} data gagal di reset`,
        false
      )
      return this.getDataFromApi()
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },

    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },
    async dropdownDepartment(p) {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${p}}]`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    async dropdownSection() {
      this.dropdown.section = []
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?parent_id=${this.paramAPI.department_id}`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.section = res.data.data)
          }
          return (this.dropdown.section = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.section = [])
        })
    },

    async dropdownEmployeeStatus() {
      axios
        .get(`${this.hrsApi}master/universal/employeestatus/dropdown`)
        .then((res) => {
          // console.log('status>>>>', res.data)
          if (res.data.status_code === '00') {
            return (this.dropdown.employee_status = res.data.data)
          }
          return (this.dropdown.employee_status = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.employee_status = [])
        })
    },

    statusdata(val) {
      switch (val) {
        case -1:
          return 'Inactive'

        case 1:
          return 'Active'

        default:
          return '-'
      }
    },
    statusEmployee(val) {
      switch (val) {
        case 1:
          return 'Kontrak'
        case 2:
          return 'Tetap'
        case 3:
          return 'Harian'
        case 4:
          return 'Paruh waktu'
        case 5:
          return 'Resign'

        default:
          return '-'
      }
    },
    async dropdownLevel() {
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.level = res.data.data)
          }
          return (this.dropdown.level = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.level = [])
        })
    },
    async getDropdownCompany() {
      if (this.userLevel !== null) {
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company =
              this.userLevel.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
      // else {
      //   this.dropdownCompanyApi()
      // }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = this.userLevel.is_support
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async exportData() {
      this.isLoadingPage = true
      await axios
        .get(
          `${this.hrsApi}employee/list?keyword=${
            this.paramAPI.keyword
          }&company_id=${
            this.paramAPI.company_id !== null ? this.paramAPI.company_id : ''
          }&order_by=${this.paramAPI.sortBy}&order_type=${
            this.paramAPI.sortType
          }&offset=0&limit=10000&source=export`
        )
        .then((res) => {
          console.log('export---->', res.data)
          if (res.data.status_code == '00') {
            const response = res.data.data
            if (response !== undefined) {
              this.exportNow(res.data.data)
            }
          } else {
            this.isLoadingPage = false
          }
          return null
        })
        .catch((error) => {
          console.log(error)
          this.isLoadingPage = false
          return null
        })
    },
    exportNow(selectedData) {
      try {
        const arrData = []
        for (let i = 0; i < selectedData.length; i++) {
          const param = selectedData[i]
          const newObject = {}
          let departmentName = null
          let departmentId = null
          if (param.department !== null) {
            if (param.department.unit !== null) {
              departmentName = param.department.unit.name
              departmentId = param.department.unit.id
            }
          }
          const xEmployeeStatusId =
            param.employee_status !== null ? param.employee_status.id : null
          const xEmployeeStatusName =
            param.employee_status !== null ? param.employee_status.name : null
          const xLevelId = param.level !== null ? param.level.id : null
          const xLevelName = param.level !== null ? param.level.name : null
          const xGenderId = param.gender !== null ? param.gender.id : null
          const xGenderName = param.gender !== null ? param.gender.name : null
          const xMarriageStatusId =
            param.mariage_status !== null ? param.mariage_status.id : null
          const xMarrieageStatusName =
            param.mariage_status !== null ? param.mariage_status.name : null
          const xPtkpStatusId =
            param.ptkp_status !== null ? param.ptkp_status.id : null
          const xPtkpStatusName =
            param.ptkp_status !== null ? param.ptkp_status.name : null
          const xTaxObjectId =
            param.tax_object !== null ? param.tax_object.id : null
          const xTaxObjectName =
            param.tax_object !== null ? param.tax_object.name : null
          const xTaxObjectCode =
            param.tax_object !== null ? param.tax_object.code : null
          const xReligionId =
            param.religion == null || param.religion == 0
              ? null
              : param.religion
          const xReligionName = this.getReligionName(param.religion)
          const xWeekendDay =
            param.weekend_day !== null ? param.weekend_day.join(',') : null
          const xShiftCategoryId =
            param.shift_category !== null ? param.shift_category.id : null
          const xShiftCategoryName =
            param.shift_category !== null ? param.shift_category.name : null

          Object.assign(newObject, {
            nik: param.nik,
            name: param.name,
            email: param.email,
            company_id: param.company_id,
            company_name: param.company_name,
            working_on_company: param.working_on_company,
            department_id: departmentId,
            department_name: departmentName,
            level_id: xLevelId,
            level_name: xLevelName,
            employee_status_id: xEmployeeStatusId,
            employee_status_name: xEmployeeStatusName,
            no_identitas: param.no_identitas,
            birth_date: param.birth_date,
            birth_place: param.birth_place,
            gender: xGenderId,
            gender_name: xGenderName,
            religion: xReligionId,
            religion_name: xReligionName,
            address: param.address,
            domicile_address: param.domicile_address,
            mobile_phone_no: param.mobile_phone_no,
            education_level: param.education_level,
            education_major: param.education_major,
            mariage_status: xMarriageStatusId,
            mariage_status_name: xMarrieageStatusName,
            gol: param.gol,
            blood_type: param.blood_type,
            card_no: param.card_no,
            clothing_size: param.clothing_size,
            account_number: param.account_number,
            join_date: param.join_date,
            contract_date: param.contract_date,
            contract_month: param.contract_month,
            expire_contract_date: param.expire_contract_date,
            resign_date: param.resign_date,
            total_leave_n: param.total_leave_n,
            total_leave_n_1: param.total_leave_n_1,
            total_leave_advance: param.total_leave_advance,
            expire_leave_n_1: param.expire_leave_n_1,
            ptkp_status_id: xPtkpStatusId,
            ptkp_status_name: xPtkpStatusName,
            npwp_no: param.npwp_no,
            bpjs_no: param.bpjs_no,
            bpjs_tk_no: param.bpjs_tk_no,
            bpjs_status: param.bpjs_status,
            bpjs_template: param.bpjs_template,
            tax_object_id: xTaxObjectId,
            tax_object_code: xTaxObjectCode,
            tax_object_name: xTaxObjectName,
            weekend_day: xWeekendDay,
            fingerprint_id: param.fingerprint_id,
            is_shift: param.is_shift,
            status: param.status,
            auto_generate_payroll: param.auto_generate_payroll,
            payroll_method: param.payroll_method,
            is_umr_based_on_company: param.is_umr_based_on_company,
            umr: param.umr,
            gaji_pokok: param.gaji_pokok,
            tunjangan_jabatan: param.tunjangan_jabatan,
            tunjangan_penempatan: param.tunjangan_penempatan,
            tunjangan_perumahan: param.tunjangan_perumahan,
            is_send_payslip: param.is_send_payslip,
            tambahan_bpjs_k: param.tambahan_bpjs_k,
            shift_category_id: xShiftCategoryId,
            shift_category_name: xShiftCategoryName
          })
          // if (
          //   this.getUserProfile.level.find(
          //     ({ id }) => id == this.appLevel.adminMaster
          //   ) != undefined
          // ) {
          //   Object.assign(newObject, {
          //     auto_generate_payroll: param.auto_generate_payroll,
          //     payroll_method: param.payroll_method,
          //     is_umr_based_on_company: param.is_umr_based_on_company,
          //     umr: param.umr,
          //     gaji_pokok: param.gaji_pokok,
          //     tunjangan_jabatan: param.tunjangan_jabatan,
          //     tunjangan_penempatan: param.tunjangan_penempatan,
          //     tunjangan_perumahan: param.tunjangan_perumahan,
          //     is_send_payslip: param.is_send_payslip,
          //     tambahan_bpjs_k: param.tambahan_bpjs_k
          //   })
          // }
          arrData.push(newObject)
        }
        console.log('response list>>>>', arrData)
        this.downloadExcell(arrData)
      } catch (error) {
        this.isLoadingPage = false
        this.showMsgDialog('error', `Export failed: ${error}`, false)
      }
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'list_karyawan.xlsx')
      setTimeout(() => {
        this.isLoadingPage = false
      }, 3000)
    },
    importData() {
      setTimeout(() => {
        document.getElementById('employeeImport').click()
      }, 200)
    },

    async resetPassword() {
      this.showMsgDialog(
        'question',
        'Password akun karyawan tersebut akan direset, Anda yakin ?',
        true,
        false
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .post(`${this.oAuthApi}user/forgot_password`, {
              email: this.selectedData[0].email
            })
            .then(async (res) => {
              if (res.data.status_code === '00') {
                return this.showMsgDialog(
                  'success',
                  res.data.status_msg,
                  'false'
                )
              }
              return this.showMsgDialog('warning', res.data.status_msg, 'false')
            })
            .catch((err) => {
              this.showMsgDialog('error', `${err}`, 'false')
              return (this.result = [])
            })
          this.loading = false
        }
        //  else {
        this.actionValue = 0
        // }
      })
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    async companyWatcher(p) {
      this.paramAPI.company_id = p
      this.resetSomeParam()
      this.dropdownDepartment(p)
      await this.getDataFromApi()
    },
    async departmentWatcher(p) {
      this.paramAPI.department_id = p
      this.resetSomeParam()
      await this.getDataFromApi()
    },
    async levelWatcher(p) {
      this.paramAPI.level_id = p
      this.resetSomeParam()
      await this.getDataFromApi()
    },
    async statusWatcher(p) {
      this.resetSomeParam()
      await this.getDataFromApi()
    },

    showlog() {
      const element1 = document.getElementById('updateHistory')
      const element2 = document.getElementById('triangle')

      if (!this.isLog) {
        // this.vendorDetail(data.id)
        this.isLog = true
        element1.classList.remove('log-hide')
        element2.classList.remove('triangle-maximize')
        // setTimeout(() => {
        element1.classList.add('log-show')
        element2.classList.add('triangle-minimize')
        // }, 0)
      } else {
        this.isLog = false
        element1.classList.remove('log-show')
        element2.classList.remove('triangle-minimize')
        // setTimeout(() => {
        element1.classList.add('log-hide')
        element2.classList.add('triangle-maximize')
        // }, 0)
      }
      // console.log('element1.classList', element1.classList)
    },

    async getLog() {
      let arrLog = []
      await axios
        .get(
          `${this.logApi}hris/master_employee/list?company_id=${this.paramAPI.company_id}`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            // console.log('LOG >>>>>>>>', res.data)
            arrLog = res.data.data
          }
          this.log = arrLog
        })
        .catch((err) => {
          this.log = arrLog
          console.log(err)
        })
    },
    getReligionName(val) {
      switch (val) {
        case 1:
          return 'Islam'
        case 2:
          return 'Kristen'
        case 3:
          return 'Hindu'
        case 4:
          return 'Buddha'
        case 5:
          return 'Kong Hu Cu'
        case 6:
          return 'Katolik'
        case 7:
          return 'Protestan'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss">
#triangle {
  position: absolute;
  z-index: 2;
  top: 25px;
}
.triangle-maximize {
  transform: rotate(0deg);
  right: 0;
  transition: 0.3s;
}
.triangle-minimize {
  transform: rotate(180deg);
  right: -50px;
  transition: 0.3s;
}
#updateHistory {
  position: relative;
  padding: 15px 0 15px 0;
  // margin-right: 15px;
}
.history-background {
  background: rgba(255, 255, 255, 0.11);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.4px);
  -webkit-backdrop-filter: blur(3.4px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  height: 100%;
  position: relative;
}
.log-show {
  width: 350px;
  transition: 0.3s;
  margin-right: 15px;
}

.log-hide {
  width: 0;
  overflow: hidden;
  transition: 0.3s;
}

.triangle-with-shadow {
  width: 60px;
  height: 60px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover:after {
    box-shadow: -1px -1px 15px -3px rgba(0, 0, 0, 0.5);
  }
  &:hover:before {
    background: rgba(255, 255, 255, 0.2);
  }
}
.triangle-with-shadow:after {
  content: '';
  position: absolute;
  z-index: 5;
  width: 60px;
  height: 60px;
  background: rgb(0, 56, 130);
  transform: rotate(45deg); /* Prefixes... */
  top: 0;
  left: 35px;
  box-shadow: -1px -1px 5px -2px rgba(0, 0, 0, 0.5);
  &:hover {
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
  }
}
.triangle-with-shadow:before {
  content: '';
  position: absolute;
  z-index: 10;
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  transform: rotate(45deg); /* Prefixes... */
  top: 14.25px;
  left: 29px;
  border-radius: 0 25px 0 0;

  &:hover {
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
  }
  // box-shadow: -1px -1px 5px -2px rgba(0, 0, 0, 0.5);
}
.p-log {
  margin: 0;
  font-size: 10px;
  color: white;
  position: relative;
  z-index: 10;
  font-weight: bolder;
}
.p-log-1 {
  transform: none;
  top: 6px;
  left: 13px;
}
.p-log-2 {
  transform: rotate(180deg);
  top: 8px;
  left: 12px;
}

.box-before-after {
  height: 30px;
  // overflow: scroll;
  overflow-y: scroll;
  // overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

#employeeList {
  position: relative;
  .employee-list-div {
    position: relative;
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .employee-list-toolbar-1 {
      height: 60px;
      border-radius: 10px 10px 0 0;
      .employee-list-toolbar-div {
        width: 400px;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .employee-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #employeeList {
    .employee-list-div {
      .employee-list-toolbar-1 {
        display: none;
      }
      .employee-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
